import React from 'react'

const Loader = () => {
  return (
    <div className='bg-white flex justify-center items-center h-screen'>

        <div>
            <img src="../../../images/assets/loader.svg" height={40} width={40} alt="loader img" />
        </div>

    </div>
  )
}

export default Loader