import React, { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Loader from './components/Loader'

const Home = React.lazy(() => import('./pages'))

const App = () => {
  return (
    <Router>
      <Suspense fallback={Loader()}>
        <Routes>
          <Route path='/' exact element={<Home />} />
        </Routes>
      </Suspense>

    </Router>
  )
}

export default App